import React from 'react';

export default function NewsletterForm() {
  return (
    <div className="bg-white p-8 rounded-lg shadow-sm">
      <h3 className="text-2xl font-bold text-gray-900 mb-6">
        Newsletter Anmeldung
      </h3>
      <form 
        method="post" 
        action="https://blog.stumptner.it/wp-admin/admin-ajax.php?action=tnp&na=s"
        className="space-y-4"
      >
        <input type="hidden" name="nlang" value="" />
        
        <div>
          <label 
            htmlFor="tnp-1" 
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Vorname
          </label>
          <input
            type="text"
            name="nn"
            id="tnp-1"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
          />
        </div>

        <div>
          <label 
            htmlFor="tnp-2" 
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            E-Mail-Adresse
          </label>
          <input
            type="email"
            name="ne"
            id="tnp-2"
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-red-500 focus:border-red-500"
          />
        </div>

        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              name="ny"
              required
              className="h-4 w-4 text-red-500 border-gray-300 rounded focus:ring-red-500"
            />
          </div>
          <div className="ml-3">
            <label className="text-sm text-gray-600">
              Hiermit akzeptiere ich die Datenschutzbestimmungen
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-600 transition"
        >
          Abonnieren
        </button>
      </form>
    </div>
  );
}