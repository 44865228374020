import { Server, Cloud } from 'lucide-react';
import { Link } from 'react-router-dom';

const products = [
  {
    icon: Server,
    title: 'VPS',
    description: 'Virtuelle Private Server mit höchster Performance und Zuverlässigkeit.',
    link: 'https://hosttropia.com'
  },
  {
    icon: Cloud,
    title: 'SaaS Dienste',
    description: 'Innovative Software-as-a-Service Lösungen für Ihr Unternehmen.',
    link: 'https://saastropia.com/'
  }
];

export default function Products() {
  return (
    <section id="products" className="py-20 bg-gray-50">
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Unsere Produkte</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Entdecken Sie unsere innovativen IT-Lösungen
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8">
          {products.map((product, index) => (
            product.link.startsWith('http') ? (
              <a
                key={index}
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
                className="p-8 bg-white border border-gray-200 rounded-lg hover:shadow-lg transition group"
              >
                <product.icon className="h-12 w-12 text-red-500 mb-4 group-hover:scale-110 transition-transform" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-red-500 transition">
                  {product.title}
                </h3>
                <p className="text-gray-600">{product.description}</p>
                <div className="mt-4 text-red-500 font-medium group-hover:translate-x-2 transition-transform">
                  Mehr erfahren →
                </div>
              </a>
            ) : (
              <Link
                key={index}
                to={product.link}
                className="p-8 bg-white border border-gray-200 rounded-lg hover:shadow-lg transition group"
              >
                <product.icon className="h-12 w-12 text-red-500 mb-4 group-hover:scale-110 transition-transform" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-red-500 transition">
                  {product.title}
                </h3>
                <p className="text-gray-600">{product.description}</p>
                <div className="mt-4 text-red-500 font-medium group-hover:translate-x-2 transition-transform">
                  Mehr erfahren →
                </div>
              </Link>
            )
          ))}
        </div>
      </div>
    </section>
  );
}