import { Server, Shield, Clock, Users, Cloud, Phone } from 'lucide-react';

const services = [
  {
    icon: Server,
    title: 'IT Infrastruktur',
    description: 'Hardware, Software, Cloud optimal konfiguriert und auf Ihre Bedürfnisse abgestimmt.'
  },
  {
    icon: Shield,
    title: 'IT-Sicherheit',
    description: 'Umfassender Schutz Ihrer Systeme vor aktuellen Bedrohungen und Cyberangriffen.'
  },
  {
    icon: Clock,
    title: '24/7 Service',
    description: 'Unser Support-Team steht Ihnen rund um die Uhr telefonisch, per Fernwartung und vor Ort zur Verfügung.'
  },
  {
    icon: Users,
    title: 'Beratung',
    description: 'Kompetente Beratung und passende Unterstützung zu allen IT-Fragen.'
  },
  {
    icon: Cloud,
    title: 'Cloud-Lösungen',
    description: 'Moderne Cloud-Services für maximale Flexibilität und Skalierbarkeit.'
  },
  {
    icon: Phone,
    title: 'Support Hotline',
    description: 'Direkter Draht zu unseren IT-Experten für schnelle Problemlösungen.'
  }
];

export default function Services() {
  return (
    <section id="services" className="py-20 bg-white">
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Unsere Leistungen</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Professionelle IT-Dienstleistungen für Ihren Geschäftserfolg
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="p-6 border border-gray-200 rounded-lg hover:shadow-lg transition">
              <service.icon className="h-12 w-12 text-red-500 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}