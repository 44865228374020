import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slides = [
  {
    title: 'Ihr zuverlässiger IT-Partner im Großraum Nürnberg',
    description: '12+ Jahre Erfahrung in der Betreuung von Unternehmen jeder Größe. Als Systemhaus sind wir Ihre professionelle IT-Abteilung.',
    image: 'https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80'
  },
  {
    title: 'Hochperformante VPS Lösungen',
    description: 'Skalierbare virtuelle Server mit garantierter Leistung und 99,9% Verfügbarkeit. Ideal für Ihre geschäftskritischen Anwendungen.',
    image: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80'
  },
  {
    title: 'Innovative SaaS Dienste',
    description: 'Cloud-basierte Softwarelösungen, die Ihr Unternehmen digitalisieren und Prozesse optimieren.',
    image: 'https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80'
  }
];

export default function Hero() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    customPaging: () => (
      <div className="w-3 h-3 mx-1 rounded-full bg-gray-300 hover:bg-red-500 transition-colors" />
    )
  };

  return (
    <div className="relative bg-gradient-to-r from-gray-50 to-gray-100 pt-20 md:pt-32 pb-16 md:pb-20 overflow-hidden">
      <div className="container mx-auto px-4 max-w-6xl">
        <Slider {...settings} className="hero-slider">
          {slides.map((slide, index) => (
            <div key={index} className="outline-none">
              <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-center">
                <div className="relative z-10 text-center md:text-left">
                  <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4 md:mb-6">
                    {slide.title}
                  </h1>
                  <p className="text-base md:text-lg text-gray-600 mb-6 md:mb-8">
                    {slide.description}
                  </p>
                  <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
                    <Link
                      to="/kontakt"
                      className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-500 hover:bg-red-600 transition w-full sm:w-auto"
                    >
                      Kontakt aufnehmen
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Link>
                    <a href="#products" 
                      className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition">
                      Produkte
                    </a>
                  </div>
                </div>
                <div className="relative mt-8 md:mt-0">
                  <img
                    src={slide.image}
                    alt="IT Support"
                    className="rounded-lg shadow-xl w-full h-[250px] md:h-[400px] object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}