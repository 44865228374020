import React from 'react';

export default function AGBPage() {
  return (
    <div className="pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="mb-8">
          <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
            <a href="/" className="hover:text-red-500">Home</a>
            <span>•</span>
            <span className="text-red-500">AGB</span>
          </div>
          <h1 className="text-3xl font-bold text-gray-900">Allgemeine Geschäftsbedingungen</h1>
        </div>

        <div className="prose max-w-none">
<h2>I. Allgemeine Geschäftsbedingungen</h2>
<p>
§ 1 Grundlegende Bestimmungen<br/>
(1) Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (Chris Stumptner) über die Internetseite Stumptner.it schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von Ihnen verwendeter eigener Bedingungen widersprochen.<br/>
(2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann. Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.<br/>

§ 2 Zustandekommen des Vertrages<br/>
(1) Gegenstand des Vertrages ist der Verkauf von Waren und/oder die Erbringung von Reparaturleistungen und/oder die Erbringung von Montageleistungen.<br/>
(2) Bereits mit dem Einstellen des jeweiligen Produkts auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines Vertrages zu den in der Artikelbeschreibung angegebenen Bedingungen.<br/>
(3) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:<br/>
Die zum Kauf beabsichtigten Waren und/oder Reparaturleistungen werden im “Warenkorb” abgelegt.<br/>
Über die entsprechende Schaltfläche in der Navigationsleiste können Sie den “Warenkorb” aufrufen und dort jederzeit Änderungen vornehmen.<br/>
Nach Aufrufen der Seite “Kasse” und der Eingabe der persönlichen Daten sowie der Zahlungs- und Versandbedingungen werden abschließend nochmals alle Bestelldaten auf der Bestellübersichtsseite angezeigt.<br/>
Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal / PayPal Express, Amazon-Payments, Sofort) nutzen, werden Sie entweder in unserem Online-Shop auf die Bestellübersichtsseite geführt oder Sie werden zunächst auf die Internetseite des Anbieters des Sofortzahl-Systems weitergeleitet.<br/>
Erfolgt die Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw. Eingabe Ihrer Daten vor.<br/>
Abschließend werden Sie zurück in unseren Online-Shop auf die Bestellübersichtsseite geleitet.<br/>
Vor Absenden der Bestellung haben Sie die Möglichkeit, hier sämtliche Angaben nochmals zu überprüfen, zu ändern (auch über die Funktion „zurück” des Internetbrowsers) bzw. den Kauf abzubrechen.<br/>
Mit dem Absenden der Bestellung über die Schaltfläche “kostenpflichtig bestellen” erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt.<br/>
(4) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen annehmen können.<br/>
(5) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.<br/>

§ 3 Zustandekommen des Vertrages bei Download-Produkten<br/>
(1) Gegenstand des Vertrages ist der Verkauf von Download-Produkten (digitale Inhalte, die nicht auf einem körperlichen Datenträger geliefert werden).<br/>
Bereits mit dem Einstellen des jeweiligen Download-Produkts auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines Vertrages zu den in der Artikelbeschreibung angegebenen Bedingungen.<br/>
(2) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:<br/>
Die zum Kauf beabsichtigten Download-Produkte werden im “Warenkorb” abgelegt. Über die entsprechende Schaltfläche in der Navigationsleiste können Sie den “Warenkorb” aufrufen und dort jederzeit Änderungen vornehmen.<br/>
Nach Aufrufen der Seite “Kasse” und der Eingabe der persönlichen Daten sowie der Zahlungsbedingungen werden abschließend nochmals alle Bestelldaten auf der Bestellübersichtsseite angezeigt.<br/>
Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal / PayPal Express, Amazon-Payments, Postpay, Sofort) nutzen, werden Sie entweder in unserem Online-Shop auf die Bestellübersichtsseite geführt oder Sie werden zunächst auf die Internetseite des Anbieters des Sofortzahl-Systems weitergeleitet.<br/>
Erfolgt die Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw. Eingabe Ihrer Daten vor.<br/>
Abschließend werden Sie zurück in unseren Online-Shop auf die Bestellübersichtsseite geleitet.<br/>
Vor Absenden der Bestellung haben Sie die Möglichkeit, hier sämtliche Angaben nochmals zu überprüfen, zu ändern (auch über die Funktion “zurück” des Internetbrowsers) bzw. den Kauf abzubrechen.<br/>
Mit dem Absenden der Bestellung über die Schaltfläche “kostenpflichtig bestellen” erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt.<br/>
(3) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen annehmen können.<br/>
(4) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.<br/>

§ 4 Nutzungslizenz bei Download-Produkten<br/>
(1) Die angebotenen Download-Produkte sind urheberrechtlich geschützt. Sie erhalten zu jedem bei uns erworbenen Download-Produkt eine einfache Nutzungslizenz, soweit im jeweiligen Angebot nichts anderes angegeben ist.<br/>
(2) Die einfache Nutzungslizenz umfasst die Erlaubnis, eine Kopie des Download-Produkts für Ihren persönlichen Gebrauch auf Ihrem Computer bzw. sonstigem elektronischen Gerät abzuspeichern und/oder auszudrucken.<br/>
Jede weitere Kopie ist Ihnen untersagt. Es ist Ihnen ausdrücklich verboten, eine Datei oder Teile davon zu verändern oder zu bearbeiten und in irgendeiner Weise Dritten privat oder kommerziell zur Verfügung zu stellen.<br/>

§ 5 Zustandekommen des Vertrages bei Kursen<br/>
(1) Gegenstand des Vertrages ist die Durchführung von Kursen.<br/>
Bereits mit dem Einstellen des jeweiligen Kursangebots auf unserer Internetseite unterbreiten wir Ihnen ein verbindliches Angebot zum Abschluss eines Vertrages zu den in der jeweiligen Kursbeschreibung angegebenen Bedingungen.<br/>
(2) Der Vertrag kommt über das Online-Warenkorbsystem wie folgt zustande:<br/>
Die zur Buchung beabsichtigten Kurse werden im “Warenkorb” abgelegt. Über die entsprechende Schaltfläche in der Navigationsleiste können Sie den “Warenkorb” aufrufen und dort jederzeit Änderungen vornehmen.<br/>
Nach Aufrufen der Seite “Kasse” und der Eingabe der persönlichen Daten sowie der Zahlungsbedingungen werden abschließend nochmals alle Bestelldaten auf der Bestellübersichtsseite angezeigt.<br/>
Soweit Sie als Zahlungsart ein Sofortzahl-System (z.B. PayPal / PayPal Express, Amazon-Payments, Postpay, Sofort) nutzen, werden Sie entweder in unserem Online-Shop auf die Bestellübersichtsseite geführt oder Sie werden zunächst auf die Internetseite des Anbieters des Sofortzahl-Systems weitergeleitet.<br/>
Erfolgt die Weiterleitung zu dem jeweiligen Sofortzahl-System, nehmen Sie dort die entsprechende Auswahl bzw. Eingabe Ihrer Daten vor.<br/>
Abschließend werden Sie zurück in unseren Online-Shop auf die Bestellübersichtsseite geleitet. Vor Absenden der Bestellung haben Sie die Möglichkeit, hier sämtliche Angaben nochmals zu überprüfen, zu ändern (auch über die Funktion “zurück” des Internetbrowsers) bzw. die Bestellung abzubrechen.<br/>
Mit dem Absenden der Bestellung über die entsprechende Schaltfläche erklären Sie rechtsverbindlich die Annahme des Angebotes, wodurch der Vertrag zustande kommt.<br/>
(3) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen annehmen können.<br/>
(4) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.<br/>

§ 6 Leistungserbringung bei Kursen<br/>
(1) Die Durchführung der Kurse in der in den jeweiligen Angeboten beschriebenen Form erfolgt zu den vereinbarten Terminen.<br/>
(2) Soweit die Durchführung der Kurse von der Teilnehmerzahl abhängig ist, ergibt sich die Mindest-Teilnehmerzahl aus dem jeweiligen Angebot. Wird die Mindest-Teilnehmerzahl nicht erreicht, informieren wir Sie spätestens 7 Tage vor Kursbeginn in Textform (z.B. per E-Mail) über das Nichtstattfinden des gebuchten Kurses. Gegebenenfalls bereits erbrachte Leistungen werden in diesem Fall unverzüglich zurückerstattet.<br/>
(3) Bei Absage einer Einzel-Veranstaltung aufgrund kurzfristigen Ausfalls des Kursleiters wegen Krankheit oder aus sonstigem wichtigen Grund werden die bereits erbrachten Leistungen unverzüglich zurückerstattet.<br/>
Bei Veranstaltungen, die aus mehreren Veranstaltungsterminen bestehen, erfolgt bei Absage eines Termins aufgrund kurzfristigen Ausfalls des Kursleiters wegen Krankheit oder aus sonstigem wichtigen Grund die Nachholung des abgesagten Termins an einem Ersatztermin.<br/>
(4) In Zusammenhang mit der Nutzung von Kursräumen und Objekten haben Sie die örtlich ausliegenden Hausordnungen einzuhalten. Sie müssen sich an unsere Weisungen bzw. die Weisungen des Kursleiters halten.<br/>

§ 7 Ersatzteilnehmer<br/>
Sie können jederzeit vor Kursbeginn einen Ersatzteilnehmer benennen. Für diese Umbuchung entstehen Ihnen keine Kosten.<br/>

§ 8 Leistungserbringung bei Montageleistungen<br/>
(1) Soweit Montageleistungen Vertragsgegenstand sind, schulden wir die sich aus der Leistungsbeschreibung ergebenden Montagearbeiten. Diese erbringen wir nach bestem Wissen und Gewissen persönlich oder durch Dritte.<br/>
(2) Die Leistungserbringung erfolgt zu den vereinbarten Terminen.<br/>
(3) Sie sind zur Mitwirkung verpflichtet. Insbesondere haben Sie zum Zeitpunkt der Erbringung der Montageleistungen die Möglichkeit zu bieten, die Räumlichkeiten in einem begehbaren und angemessen gefahrenfreien Zustand zu begehen. Des Weiteren sind Sie verpflichtet, uns soweit notwendig elektrische Energie und gegebenenfalls Wasser zur Verfügung zu stellen. Wir sind berechtigt den Vertragsteil über die Erbringung von Montageleistungen nach § 643 BGB zu kündigen, soweit Sie Ihren Mitwirkungspflichten nicht nachkommen. Hierzu setzen wir Ihnen eine angemessene Frist, während der Sie die nötigen Mitwirkungsleistungen nachholen können.<br/>
(4) Machen Sie von Ihrem Kündigungsrecht nach § 648 S. 1 BGB Gebrauch, können wir als pauschale Vergütung 10% der vereinbarten Vergütung verlangen, wenn die Ausführung noch nicht begonnen hat. Dies gilt bei Bestehen des gesetzlichen Widerrufsrechts jedoch nur, wenn Sie erst nach Ablauf der Widerrufsfrist von Ihrem Kündigungsrecht Gebrauch machen. Ihnen bleibt der Nachweis vorbehalten, dass uns tatsächlich keine oder wesentlich geringere Kosten entstanden sind.<br/>

§ 9 Leistungserbringung bei Reparaturen<br/>
(1) Soweit Reparaturleistungen Vertragsgegenstand sind, schulden wir die sich aus der Leistungsbeschreibung ergebenden Reparaturarbeiten. Diese erbringen wir nach bestem Wissen und Gewissen persönlich oder durch Dritte.<br/>
(2) Sie sind zur Mitwirkung verpflichtet, insbesondere haben Sie den am Gerät bestehenden Defekt so umfassend als möglich zu beschreiben und das defekte Gerät zur Verfügung zu stellen.<br/>
(3) Sie tragen die Kosten für die Übersendung des defekten Gerätes an uns.<br/>
(4) Soweit im jeweiligen Angebot nichts anderes angegeben ist, erfolgt die Reparatur einschließlich Aufgabe des Gerätes zum Versand innerhalb von 5 – 7 Tagen nach Zugang des zu reparierenden Gerätes (bei vereinbarter Vorauszahlung jedoch erst nach dem Zeitpunkt Ihrer Zahlungsanweisung).<br/>
(5) Machen Sie von Ihrem Kündigungsrecht nach § 648 S. 1 BGB Gebrauch, können wir als pauschale Vergütung 10% der vereinbarten Vergütung verlangen, wenn die Ausführung noch nicht begonnen hat. Dies gilt bei Bestehen des gesetzlichen Widerrufsrechts jedoch nur, wenn Sie erst nach Ablauf der Widerrufsfrist von Ihrem Kündigungsrecht Gebrauch machen. Ihnen bleibt der Nachweis vorbehalten, dass uns tatsächlich keine oder wesentlich geringere Kosten entstanden sind.<br/>

§ 10 Besondere Vereinbarungen zu angebotenen Zahlungsarten<br/>
(1) Bonitätsprüfung<br/>
Sofern wir in Vorleistung treten, z.B. bei Zahlung auf Rechnung oder Lastschrift, werden Ihre Daten zur Wahrung unserer berechtigten Interessen zum Zwecke der Bonitätsprüfung auf der Basis mathematisch-statistischer Verfahren an die infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden, weitergegeben. Wir behalten uns das Recht vor, Ihnen im Ergebnis der Bonitätsprüfung die Zahlungsart auf Rechnung oder Lastschrift zu verweigern.<br/>
(2) Ratenkauf über easyCredit<br/>
Beim Ratenkauf über easyCredit gelten ergänzend die Allgemeinen Geschäftsbedingungen zum Ratenkauf von easyCredit. Sie finden die AGB hier.<br/>
(3) Zahlung per Rechnung und Finanzierung über Klarna Deutschland<br/>
In Zusammenarbeit mit Klarna bieten wir Ihnen den Rechnungskauf und den Ratenkauf als Zahlungsoption an. Bitte beachten Sie, dass Klarna Rechnung und Klarna Ratenkauf nur für Verbraucher verfügbar sind und dass die Zahlung jeweils an Klarna zu erfolgen hat.<br/>
Klarna Rechnung<br/>
Beim Kauf auf Rechnung mit Klarna bekommen Sie immer zuerst die Ware und Sie haben immer eine Zahlungsfrist von 14 Tagen. Die vollständigen AGB zum Rechnungskauf finden Sie hier.<br/>
Klarna Ratenkauf<br/>
Mit dem Finanzierungsservice von Klarna können Sie Ihren Einkauf flexibel in monatlichen Raten von mindestens 1/24 des Gesamtbetrages (mindestens jedoch 6,95 €) bezahlen. Weitere Informationen zum Klarna Ratenkauf einschließlich der Allgemeinen Geschäftsbedingungen und der europäischen Standardinformationen für Verbraucherkredite finden Sie hier.<br/>

§ 11 Zurückbehaltungsrecht, Eigentumsvorbehalt<br/>
(1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis handelt.<br/>
(2) Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.<br/>
(3) Sind Sie Unternehmer, gilt ergänzend Folgendes:<br/>
a) Wir behalten uns das Eigentum an der Ware bis zum vollständigen Ausgleich aller Forderungen aus der laufenden Geschäftsbeziehung vor. Vor Übergang des Eigentums an der Vorbehaltsware ist eine Verpfändung oder Sicherheitsübereignung nicht zulässig.<br/>
b) Sie können die Ware im ordentlichen Geschäftsgang weiterverkaufen. Für diesen Fall treten Sie bereits jetzt alle Forderungen in Höhe des Rechnungsbetrages, die Ihnen aus dem Weiterverkauf erwachsen, an uns ab, wir nehmen die Abtretung an. Sie sind weiter zur Einziehung der Forderung ermächtigt. Soweit Sie Ihren Zahlungsverpflichtungen nicht ordnungsgemäß nachkommen, behalten wir uns allerdings vor, die Forderung selbst einzuziehen.<br/>
c) Bei Verbindung und Vermischung der Vorbehaltsware erwerben wir Miteigentum an der neuen Sache im Verhältnis des Rechnungswertes der Vorbehaltsware zu den anderen verarbeiteten Gegenständen zum Zeitpunkt der Verarbeitung.<br/>
d) Wir verpflichten uns, die uns zustehenden Sicherheiten auf Ihr Verlangen insoweit freizugeben, als der realisierbare Wert unserer Sicherheiten die zu sichernde Forderung um mehr als 10% übersteigt. Die Auswahl der freizugebenden Sicherheiten obliegt uns.<br/>

§ 12 Gewährleistung<br/>
(1) Es bestehen die gesetzlichen Mängelhaftungsrechte.<br/>
(2) Bei gebrauchten Sachen sind die Mängelansprüche ausgeschlossen, wenn sich der Mangel erst nach Ablauf eines Jahres ab Ablieferung der Sache zeigt. Zeigt sich der Mangel innerhalb eines Jahres ab Ablieferung der Sache, können die Mängelansprüche im Rahmen der gesetzlichen Verjährungsfrist von zwei Jahren ab Ablieferung der Sache geltend gemacht werden.<br/>
Die vorstehende Einschränkung gilt nicht:<br/>

für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;<br/>
soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben.<br/>
(3) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht nach, hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.<br/>
(4) Soweit Sie Unternehmer sind, gilt abweichend von den vorstehenden Gewährleistungsregelungen:<br/>
a) Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als vereinbart, nicht jedoch sonstige Werbung, öffentliche Anpreisungen und Äußerungen des Herstellers.<br/>
b) Bei Mängeln leisten wir nach unserer Wahl Gewähr durch Nachbesserung oder Nachlieferung. Schlägt die Mangelbeseitigung fehl, können Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zurücktreten. Die Mängelbeseitigung gilt nach erfolglosem zweiten Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den sonstigen Umständen etwas anderes ergibt. Im Falle der Nachbesserung müssen wir nicht die erhöhten Kosten tragen, die durch die Verbringung der Ware an einen anderen Ort als den Erfüllungsort entstehen, sofern die Verbringung nicht dem bestimmungsgemäßen Gebrauch der Ware entspricht.<br/>
c) Die Gewährleistungsfrist beträgt ein Jahr ab Ablieferung der Ware. Die Fristverkürzung gilt nicht:<br/>
für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;<br/>
soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben;<br/>
bei Sachen, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet worden sind und dessen Mangelhaftigkeit verursacht haben;<br/>
bei gesetzlichen Rückgriffsansprüchen, die Sie im Zusammenhang mit Mängelrechten gegen uns haben.<br/>
§ 13 Rechtswahl<br/>
(1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).<br/>
(2) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.<br/>
</p>
          {/* Rest of the content follows the same structure */}
        </div>
      </div>
    </div>
  );
}