import { FC } from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = ({ className = "h-12" }) => {
  const content = (
    <div className={`flex items-center ${className}`}>
      <div className="flex items-center gap-2">
        <div className="flex">
          {/* Server Icons */}
          <img src="https://blog.stumptner.it/wp-content/uploads/2024/12/1694657339stuit-3-e1733601372922.png" />
        </div>
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-red-500">Stumptner IT</span>
          <span className="text-sm text-gray-600">IT Dienstleistungen aus Nürnberg</span>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Logo;