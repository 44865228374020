import { Users, Award, Clock } from 'lucide-react';

export default function About() {
  return (
    <section id="about" className="py-20 bg-gray-50">
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">110% Für unsere Projekte</h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Ihr zuverlässiger IT-Dienstleister im Großraum Nürnberg
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="grid grid-cols-2 gap-4">
            <img 
              src="https://images.unsplash.com/photo-1581092160562-40aa08e78837?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=80"
              alt="IT Service 1"
              className="rounded-lg shadow-md"
            />
            <img 
              src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=80"
              alt="IT Service 2"
              className="rounded-lg shadow-md mt-8"
            />
          </div>
          
          <div>
            <div className="mb-8">
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Wir beraten und betreuen Unternehmen jeder Größe.
              </h3>
              <p className="text-gray-600 mb-4">
                Als Systemhaus sind wir Ihre professionelle IT-Abteilung!
              </p>
            </div>

            <div className="space-y-6">
              <div className="flex items-start">
                <Users className="h-6 w-6 text-red-500 mt-1 mr-4" />
                <div>
                  <h4 className="font-semibold text-gray-900">Erfahrenes Team</h4>
                  <p className="text-gray-600">Hochqualifizierte IT-Experten mit langjähriger Erfahrung</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <Award className="h-6 w-6 text-red-500 mt-1 mr-4" />
                <div>
                  <h4 className="font-semibold text-gray-900">Zertifizierte Qualität</h4>
                  <p className="text-gray-600">Höchste Standards und mehrfache Zertifizierungen</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <Clock className="h-6 w-6 text-red-500 mt-1 mr-4" />
                <div>
                  <h4 className="font-semibold text-gray-900">24/7 Support</h4>
                  <p className="text-gray-600">Schnelle Reaktionszeiten und kompetente Hilfe</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}