import Hero from '../components/Hero';
import Services from '../components/Services';
import About from '../components/About';
import Projects from '../components/Projects';
import Products from '../components/Products';

export default function HomePage() {
  return (
    <>
      <Hero />
      <Services />
      <Products />
      <About />
      <Projects />
    </>
  );
}