import ContactInfo from '../components/ContactInfo';

export default function ContactPage() {
  return (
    <div className="pt-20">
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="flex items-center justify-center gap-2 text-sm text-gray-600 mb-2">
              <a href="/" className="hover:text-red-500">Home</a>
              <span>•</span>
              <span className="text-red-500">Kontakt</span>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">Kontaktieren Sie uns</h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Wir freuen uns auf Ihre Nachricht und beraten Sie gerne zu allen IT-Fragen
            </p>
          </div>

          <ContactInfo />
          
          <div className="mt-16 text-center">
            <p className="text-lg text-gray-600">
              Sie können uns auch direkt per Email an{' '}
              <a 
                href="mailto:anfragen@stumptner.it" 
                className="text-red-500 hover:text-red-600 font-medium"
              >
                anfragen@stumptner.it
              </a>
              {' '}kontaktieren.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}