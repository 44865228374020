import { Menu, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './Logo';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigation = (hash: string) => {
    setIsOpen(false);
    navigate(`/${hash}`);
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white shadow-md' : 'bg-white/95 shadow-sm'
    }`}>
      <div className="container mx-auto">
        <div className="flex justify-between items-center h-16 md:h-20 px-4">
          <Link to="/" className="flex items-center">
            <Logo className="h-8 md:h-12" />
          </Link>
          
          <div className="hidden md:flex items-center space-x-6 lg:space-x-8">
            <Link 
              to="https://stumptner.it/" 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Startseite
            </Link>
            <button 
              onClick={() => handleNavigation('#services')} 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Leistungen
            </button>
            <button 
              onClick={() => handleNavigation('#products')} 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Produkte
            </button>
            <button 
              onClick={() => handleNavigation('#about')} 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Über Uns
            </button>
            <button 
              onClick={() => handleNavigation('#projects')} 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Projekte
            </button>
            <Link 
              to="/kontakt" 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Kontakt
            </Link>
              <Link 
              to="https://blog.stumptner.it" 
              className="text-gray-700 hover:text-red-500 transition text-sm lg:text-base"
            >
              Blog
            </Link>
            <a 
              href="https://invoice.stumptner.it/client/login" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition text-sm lg:text-base"
            >
              Kundenlogin
            </a>
          </div>

          <button 
            onClick={() => setIsOpen(!isOpen)} 
            className="md:hidden p-2 text-gray-700 hover:text-red-500 transition"
          >
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden fixed inset-0 top-16 bg-white z-40 mobile-nav">
          <div className="flex flex-col px-4 pt-2 pb-3 space-y-2 h-full overflow-y-auto">
            <Link 
              to="/" 
              className="px-3 py-2 text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition"
              onClick={() => setIsOpen(false)}
            >
              Startseite
            </Link>
            <button 
              onClick={() => handleNavigation('#services')}
              className="px-3 py-2 text-left text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition w-full"
            >
              Leistungen
            </button>
            <button 
              onClick={() => handleNavigation('#products')}
              className="px-3 py-2 text-left text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition w-full"
            >
              Produkte
            </button>
            <button 
              onClick={() => handleNavigation('#about')}
              className="px-3 py-2 text-left text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition w-full"
            >
              Über Uns
            </button>
            <button 
              onClick={() => handleNavigation('#projects')}
              className="px-3 py-2 text-left text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition w-full"
            >
              Projekte
            </button>
            <Link 
              to="/kontakt" 
              className="px-3 py-2 text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition"
              onClick={() => setIsOpen(false)}
            >
              Kontakt
            </Link>
              <Link 
              to="https://blog.stumptner.it" 
              className="px-3 py-2 text-gray-700 hover:text-red-500 hover:bg-gray-50 rounded-md transition"
              onClick={() => setIsOpen(false)}
            >
              Blog
            </Link>
            <a 
              href="https://invoice.stumptner.it/client/login" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="px-3 py-2 text-red-500 hover:text-red-600 hover:bg-gray-50 rounded-md transition font-medium"
              onClick={() => setIsOpen(false)}
            >
              Kundenlogin
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}