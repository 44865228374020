import { Phone, Mail, MapPin } from 'lucide-react';

export default function ContactInfo() {
  return (
    <div className="grid md:grid-cols-3 gap-8">
      <div className="bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition">
        <Phone className="h-10 w-10 text-red-500 mb-4" />
        <h3 className="text-xl font-semibold mb-4">Rufen Sie uns an</h3>
        <p className="text-gray-600 mb-2">TEL: 0911-14886944</p>
        <p className="text-gray-600">FAX: 0911-93114949</p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition">
        <Mail className="h-10 w-10 text-red-500 mb-4" />
        <h3 className="text-xl font-semibold mb-4">Unser Email Kontakt</h3>
        <a href="mailto:anfragen@stumptner.it" className="text-red-500 hover:text-red-600">
          anfragen@stumptner.it
        </a>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition">
        <MapPin className="h-10 w-10 text-red-500 mb-4" />
        <h3 className="text-xl font-semibold mb-4">Unsere Adresse</h3>
        <p className="text-gray-600">Weiltinger Straße 44</p>
        <p className="text-gray-600">D-90449 Nürnberg</p>
      </div>
    </div>
  );
}