import NewsletterForm from '../components/NewsletterForm';

export default function NewsletterPage() {
  return (
    <div className="pt-20">
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="flex items-center justify-center gap-2 text-sm text-gray-600 mb-2">
              <a href="/" className="hover:text-red-500">Home</a>
              <span>•</span>
              <span className="text-red-500">Newsletter</span>
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">IT Newsletter</h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Bleiben Sie auf dem Laufenden mit unseren IT-News und Expertentipps
            </p>
          </div>
          <div className="max-w-xl mx-auto">
            <NewsletterForm />
          </div>
        </div>
      </section>
    </div>
  );
}