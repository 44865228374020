import React from 'react';

export default function DatenschutzPage() {
  return (
    <div className="pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="mb-8">
          <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
            <a href="/" className="hover:text-red-500">Home</a>
            <span>•</span>
            <span className="text-red-500">Datenschutz</span>
          </div>
          <h1 className="text-3xl font-bold text-gray-900">Datenschutzerklärung</h1>
        </div>

        <div className="prose max-w-none">
          <p>
          Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich.<br/>
Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.<br/>
“Personenbezogene Daten” sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.<br/>

Server-Logfiles<br/>
Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.<br/>
Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet-Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert.<br/>
Zu diesen gespeicherten Daten gehören z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.<br/>

Kontakt<br/>
Verantwortlicher/Datenschutzbeauftragter<br/>
Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des für die Datenverarbeitung Verantwortlichen finden Sie in unserem Impressum.<br/>
Unseren Datenschutzbeauftragten erreichen Sie direkt unter:<br/>

Stumptner IT / Phillip Bauer<br/>
Weiltinger Str. 44<br/>
90449 Nürnberg<br/>
p.bauer@stumptner.it<br/>

Initiativ-Kontaktaufnahme des Kunden per E-Mail<br/>
Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang.<br/>
Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.<br/>
Wenn die Kontaktaufnahme der Durchführung vorvertraglicher Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.<br/>
Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage.<br/>
In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.<br/>
Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.<br/>

Erhebung und Verarbeitung bei Nutzung des Kontaktformulars<br/>
Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang.<br/>
Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme.<br/>
Mit Absenden Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.<br/>

Erhebung und Verarbeitung bei Nutzung des Kontaktformulars<br/>
Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang.<br/>
Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme.<br/>
Mit Absenden Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten Daten ein.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage.<br/>
Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.<br/>

WhatsApp Business<br/>
Wenn Sie per WhatsApp mit uns in Geschäftskontakt treten, nutzen wir hierfür die Version WhatsApp Business der WhatsApp Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland; “WhatsApp”).<br/>
Soweit Sie Ihren Aufenthalt außerhalb des Europäischen Wirtschaftsraumes haben, wird dieser Dienst durch die WhatsApp Inc. (1601 Willow Road, Menlo Park, CA 94025, USA) bereitgestellt.<br/>
Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.<br/>
Zu diesem Zweck erheben und verarbeiten wir Ihre bei WhatsApp hinterlegte Mobilfunknummer, falls bereitgestellt Ihren Namen sowie weitere Daten in dem von Ihnen zur Verfügung gestellten Umfang.<br/>
Wir verwenden für den Dienst ein mobiles Endgerät, in dessen Adressbuch ausschließlich Daten von Nutzern gespeichert sind, die uns über WhatsApp kontaktiert haben.<br/>
Eine Weitergabe personenbezogener Daten an WhatsApp, ohne dass Sie hierin bereits gegenüber WhatsApp eingewilligt haben, erfolgt damit nicht.<br/>
Ihre Daten werden von WhatsApp an Server der Facebook Inc. in den USA übermittelt.<br/>

Wenn die Kontaktaufnahme der Durchführung vorvertraglicher Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.<br/>
Erfolgt die Kontaktaufnahme aus anderen Gründen, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Bereitstellen einer schnellen und einfachen Kontaktaufnahme sowie an der Beantwortung Ihrer Anfrage.<br/>
In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.<br/>
Ihre personenbezogenen Daten nutzen wir nur zur Bearbeitung Ihrer Anfrage.<br/>
Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.<br/>

Nähere Informationen zu Nutzungsbedingungen und Datenschutz bei Nutzung von WhatsApp finden Sie unter:<br/>
https://www.whatsapp.com/legal/#terms-of-service und https://www.whatsapp.com/legal/#privacy-policy.<br/>

Kundenkonto und Bestellungen<br/>

Kundenkonto<br/>
Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegebenen Umfang.<br/>
Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Ihr Kundenkonto wird anschließend gelöscht.<br/>

Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen<br/>
Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist.<br/>
Die Bereitstellung der Daten ist für den Vertragsschluss erforderlich.<br/>
Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.<br/>
Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten Versandunternehmen und Dropshipping-Anbieter, Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und IT-Dienstleister.<br/>
In allen Fällen beachten wir strikt die gesetzlichen Vorgaben.<br/>
Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.<br/>

Bewertungen und Werbung<br/>

Datenerhebung bei Verfassung eines Kommentars<br/>
Bei der Kommentierung eines Artikels oder eines Beitrages erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Kommentartext) nur in dem von Ihnen zur Verfügung gestellten Umfang.<br/>
Die Verarbeitung dient dem Zweck, eine Kommentierung zu ermöglichen und Kommentare anzuzeigen.<br/>
Mit Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Ihre personenbezogenen Daten werden anschließend gelöscht.<br/>
Bei Veröffentlichung Ihres Kommentars wird nur der von Ihnen angegebene Name veröffentlicht.<br/>
Darüber hinaus wird bei Abgabe des Kommentars Ihre IP-Adresse gespeichert zu dem Zweck, einen Missbrauch der Kommentarfunktion zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.<br/>
Mit Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Ihre IP-Adresse wird anschließend gelöscht.<br/>

Verwendung Ihrer personenbezogenen Daten für die Zusendung postalischer Werbung<br/>
Wir nutzen Ihre personenbezogenen Daten (Name, Anschrift), die wir im Rahmen des Verkaufs einer Ware oder Dienstleistung erhalten haben, um Ihnen postalische Werbung zuzusenden, sofern Sie dieser Verwendung nicht widersprochen haben.<br/>
Die Bereitstellung dieser Daten ist für den Vertragsschluss erforderlich.<br/>
Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an Direktwerbung.<br/>
Sie können dieser Verwendung Ihrer Adressdaten jederzeit durch Mitteilung an uns widersprechen.<br/>
Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum.<br/>

Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung<br/>
Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben, für die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind, die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben.<br/>
Die Bereitstellung der E-Mail-Adresse ist für den Vertragsschluss erforderlich.<br/>
Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an Direktwerbung.<br/>
Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch Mitteilung an uns widersprechen.<br/>
Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum.<br/>
Sie können auch den dafür vorgesehenen Link in der Werbe-E-Mail nutzen.<br/>
Hierfür entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.<br/>

Versanddienstleister und Warenwirtschaft<br/>

Weitergabe der E-Mail-Adresse an Versandunternehmen zur Information über den Versandstatus<br/>
Wir geben Ihre E-Mail-Adresse im Rahmen der Vertragsabwicklung an das Transportunternehmen weiter, sofern Sie dem ausdrücklich im Bestellvorgang zugestimmt haben.<br/>
Die Weitergabe dient dem Zweck, Sie per E-Mail über den Versandstatus zu informieren.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können die Einwilligung jederzeit durch Mitteilung an uns oder das Transportunternehmen widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>

Nutzung eines externen Warenwirtschaftssystems<br/>
Wir verwenden zur Vertragsabwicklung ein Warenwirtschaftssystem im Rahmen einer Auftragsverarbeitung.<br/>
Dazu werden Ihre im Rahmen der Bestellung erhobenen personenbezogenen Daten an:<br/>
JTL-Software-GmbH, Rheinstr. 7, 41836 Hückelhoven übermittelt.<br/>

Zahlungsdienstleister und Bonitätsauskunft<br/>

Verwendung von PayPal<br/>
Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung.<br/>
Diese finden Sie unter: https://www.paypal.com/de/webapps/mpp/ua/privacy-full<br/>

Datenerhebung und Verarbeitung bei Anmeldung zum Ratenkauf über easyCredit<br/>
Bei Bezahlung per Ratenkauf über easyCredit gelten die ergänzenden Datenschutzhinweise zum Ratenkauf von easyCredit.<br/>

Nutzung des Klarna Checkouts<br/>
Die Checkout-Lösung wird von der Klarna AB (Sveavägen 46, 111 34 Stockholm, Schweden) zur Verfügung gestellt und nutzt Cookies, um Ihnen bei der Nutzung des Checkouts ein ideales, auf Sie zugeschnittenes Online-Erlebnis zu ermöglichen.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung.<br/>
Sie können Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>
Sie können dazu die Speicherung der Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.<br/>
Eine detaillierte Auflistung der Cookies und eine Erläuterung ihres jeweiligen Zwecks finden Sie hier:
https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_at/checkout.pdf<br/>

Datenerhebung und Verarbeitung bei Bonitätsprüfung<br/>
Sofern wir in Vorleistung treten, z. B. bei Zahlung auf Rechnung oder Lastschrift, behalten wir uns das Recht vor, ggf. eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren unter Nutzung der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden, einzuholen.<br/>
Hierzu übermitteln wir die zu einer Bonitätsprüfung benötigten personenbezogenen Daten dorthin und verwenden die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene Entscheidung über die Begründung, Durchführung oder Beendigung des Vertragsverhältnisses.<br/>
Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet werden und in deren Berechnung unter anderem Anschriftendaten einfließen.<br/>
Ihre schutzwürdigen Belange werden gemäß den gesetzlichen Bestimmungen berücksichtigt.<br/>
Die Datenverarbeitung dient dem Zweck der Bonitätsprüfung für eine Vertragsanbahnung.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse am Schutz vor Zahlungsausfall, wenn wir in Vorleistung gehen.<br/>
Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f DSGVO beruhende Verarbeitung Sie betreffender personenbezogener Daten durch Mitteilung an uns zu widersprechen.<br/>
Die Bereitstellung der Daten ist für den Vertragsschluss mit der von Ihnen gewünschten Zahlart erforderlich.<br/>
Eine Nichtbereitstellung hat zur Folge, dass der Vertrag nicht mit der von Ihnen gewählten Zahlart geschlossen werden kann.<br/>

Cookies<br/>
Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden.<br/>
Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden.<br/>
Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.<br/>
Cookies werden auf Ihrem Rechner gespeichert.<br/>
Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern.<br/>
Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können.<br/>
Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:<br/>

Chrome Browser: https://support.google.com/accounts/answer/61416?hl=de<br/>
Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies<br/>
Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen<br/>
Safari: https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac<br/>

Analyse, Kommunikation und Affiliate<br/>

Verwendung von Google Analytics<br/>
Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).<br/>
Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche.<br/>
Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br/>

Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher sowie für Marketing- und Werbezwecke.<br/>
Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.<br/>
Dabei können u.a. folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Klickpfad, Informationen über den von Ihnen verwendeten Browser und das von Ihnen verwendete Device (Gerät), besuchte Seiten, Referrer-URL (Webseite, über die Sie unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivitäten.<br/>
Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br/>

Google Analytics verwendet Technologien wie Cookies, Webspeicher im Browser und Zählpixel, die eine Analyse der Benutzung der Website durch Sie ermöglichen.<br/>
Die dadurch erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.<br/>

Auf dieser Website ist die IP-Anonymisierung aktiviert.<br/>
Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.<br/>
Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.<br/>

Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 15 Abs. 3 S. 1 TMG.<br/>
Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website.<br/>

Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.<br/>

Sie können die Erfassung der durch Google Analytics erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:<br/>
https://tools.google.com/dlpage/gaoptout?hl=de<br/>

Um die Datenerfassung und -speicherung durch Google Analytics geräteübergreifend zu verhindern, können Sie einen Opt-Out-Cookie setzen.<br/>
Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website.<br/>
Sie müssen das Opt-Out auf allen genutzten Systemen und Geräten durchführen, damit dies umfassend wirkt.<br/>
Wenn Sie das Opt-Out-Cookie löschen, werden wieder Anfragen an Google übermittelt.<br/>
Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren.<br/>

Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter:<br/>
https://www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies sowie unter https://policies.google.com/technologies/cookies?hl=de.<br/>

Nutzung von Microsoft Teams<br/>
Wir nutzen zur Durchführung unserer Online-Seminare/-Kurse (nachfolgend: Online-Seminare) im Rahmen eines Auftragsverarbeitungsvertrages das Videokonferenz-Tool Microsoft Teams der Microsoft Corporation (One Microsoft Way, Redmond, WA 98052-6399, USA; “Microsoft”).<br/>

Die Teilnahme an unseren Online-Seminaren kann über die Microsoft Teams App (nach Download auf der Webseite des Anbieters) oder über die Browser-Version auf der Webseite des Anbieters erfolgen.<br/>
Für die Teilnahme an unseren Online-Seminaren ist es also erforderlich, zumindest einmalig die Webseite des Anbieters Microsoft zu besuchen.<br/>
Für die dort stattfindende Verarbeitung Ihrer personenbezogenen Daten ist Microsoft verantwortlich.<br/>

Für die im Zusammenhang mit der Durchführung unserer Online-Seminare stattfindende Verarbeitung Ihrer personenbezogenen Daten sind wir verantwortlich.<br/>
Diese Datenverarbeitung dient dem Zweck, unser Online-Seminar durchzuführen sowie vor- und nachzubereiten.<br/>

Nutzung von Zoom<br/>
Wir nutzen zur Durchführung unserer Online-Seminare/-Kurse (nachfolgend: Online-Seminare) im Rahmen eines Auftragsverarbeitungsvertrages das Videokonferenz-Tool Zoom der Zoom Video Communications, Inc. (55 Almaden Boulevard, San Jose, CA 95113, USA; „Zoom“).<br/>

Die Teilnahme an unseren Online-Seminaren kann über die Zoom-App (nach Download auf der Webseite des Anbieters) oder über die Browser-Version auf der Webseite des Anbieters erfolgen.<br/>
Für die Teilnahme an unseren Online-Seminaren ist es also erforderlich, zumindest einmalig die Webseite des Anbieters Zoom zu besuchen.<br/>
Für die dort stattfindende Verarbeitung Ihrer Daten ist Zoom verantwortlich.<br/>

Für die im Zusammenhang mit der Durchführung unserer Online-Seminare stattfindende Verarbeitung Ihrer personenbezogenen Daten sind wir verantwortlich.<br/>
Diese Datenverarbeitung dient dem Zweck, unser Online-Seminar durchzuführen sowie vor- und nachzubereiten.<br/>
Zu diesem Zweck erheben und verarbeiten wir folgende Ihrer personenbezogenen Daten:<br/>

Name<br/>
E-Mail-Adresse<br/>
Passwort (soweit kein Single-Sign-On verwendet wurde)<br/>
IP-Adresse<br/>
Informationen zum verwendeten Gerät<br/>
Datum/Uhrzeit des Online-Seminars<br/>
Bei Telefoneinwahl die ein- und ausgehende Rufnummer und das Land.<br/>
Die Bereitstellung dieser Daten ist für die Erfüllung des zwischen Ihnen und uns geschlossenen Vertrages über die Erbringung des Online-Seminars erforderlich.<br/>
Die Nichtbereitstellung dieser Daten hat zur Folge, dass Sie nicht an unserem Online-Seminar teilnehmen können.<br/>

Darüber hinaus erheben und verarbeiten wir von Ihnen während des Online-Seminars gemachte Texteingaben sowie Video- und Audioübertragungen Ihres Geräts.<br/>
Sie können Ihre Kamera und Ihr Mikrofon vor dem Betreten des Online-Seminars und jederzeit während des Online-Seminars deaktivieren.<br/>
Hierüber findet dann keine Datenerhebung statt.<br/>

Falls Sie weder über die Chat-, Kamera- oder Audiofunktion Daten bereitstellen wollen, ist keine aktive Teilnahme am Online-Seminar oder Kommunikation mit anderen Teilnehmern möglich.<br/>

Soweit wir unsere Online-Seminare zu Nachbereitungszwecken, insbesondere zum nachträglichen Bereitstellen für die jeweiligen Teilnehmer, aufzeichnen, werden Sie hierüber gesondert vor Beginn des Online-Seminars informiert.<br/>
Sie erkennen eine Aufnahme zudem an der Kennzeichnung während des Online-Seminars.<br/>
Bei einer Aufzeichnung werden Ihr Name, ggf. gemachte Texteingaben im Chat sowie Video-/Audioübertragungen Ihres Geräts gespeichert und ggf. im Nachgang an die jeweiligen Teilnehmer des Online-Seminars übermittelt.<br/>

Die bei der Durchführung unserer Online-Seminare erhobenen Daten werden im Rahmen des Auftragsverarbeitungsvertrages an Zoom und damit in die USA übermittelt.<br/>
Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.<br/>

Nähere Informationen zur Datenverarbeitung bei Nutzung von Zoom finden Sie unter: https://zoom.us/de-de/privacy.html<br/>

Verwendung des Amazon Partnerprogramms<br/>
Wir nutzen das Partnerprogramm „AmazonPartnerNet“ der Amazon EU S.a.r.l. (5 Rue Plaetis, L-2338 Luxemburg; „Amazon“).<br/>
Wir haben auf unserer Website Werbeanzeigen als Links zu Angeboten auf verschiedenen Amazon-Webseiten eingerichtet.<br/>
Amazon verwendet Cookies.<br/>
Die Cookies dienen dem Zweck der korrekten Abrechnung im Rahmen des Partnerprogramms.<br/>
Durch die Cookies kann Amazon feststellen, dass Sie auf einen Anzeige-Link geklickt haben und kann die Herkunft der Bestellung, die über den Werbe-Link generiert wurde, nachvollziehen.<br/>

Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6 Abs. 1 lit. a DSGVO.<br/>
Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO.<br/>
Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br/>

Die Datenschutzerklärung mit ausführlichen Informationen zur Verwendung der Daten durch Amazon finden Sie unter:<br/>
https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401<br/>

Plug-ins und Sonstiges<br/>

Verwendung des Google Tag Managers<br/>
Wir verwenden auf unserer Website den Google Tag Manager der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).<br/>
Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche.<br/>
Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br/>

Mit dieser Anwendung werden JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung insbesondere von Tracking- und Analyse-Tools verwendet werden.<br/>
Die Datenverarbeitung dient dem Zweck der bedarfsgerechten Gestaltung und der Optimierung unserer Website.<br/>

Der Google Tag Manager selbst speichert weder Cookies noch werden hierdurch personenbezogene Daten verarbeitet.<br/>
Er ermöglicht jedoch die Auslösung weiterer Tags, die personenbezogene Daten erheben und verarbeiten können.<br/>

Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie hier: https://www.google.com/analytics/tag-manager/use-policy/<br/>

Verwendung von Google reCAPTCHA<br/>
Wir verwenden auf unserer Website den Dienst reCAPTCHA der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).<br/>
Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche.<br/>
Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br/>

Die Abfrage dient dem Zweck der Unterscheidung der Eingabe durch einen Menschen oder durch automatisierte, maschinelle Verarbeitung.<br/>
Dazu wird Ihre Eingabe an Google übermittelt und dort weiterverwendet.<br/>
Zusätzlich werden die IP-Adresse und gegebenenfalls weitere von Google für den Dienst reCAPTCHA benötigte Daten an Google übertragen.<br/>
Diese Daten werden von Google innerhalb der Europäischen Union verarbeitet und gegebenenfalls auch in die USA übermittelt.<br/>

Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus dem berechtigten Interesse, unsere Website vor automatisierter Ausspähung, Missbrauch und SPAM zu schützen.<br/>

Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.<br/>

Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden Sie unter:<br/>
https://www.google.com/recaptcha/intro/android.html sowie https://www.google.com/privacy<br/>

Betroffenenrechte und Speicherdauer<br/>

Dauer der Speicherung<br/>
Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist gespeichert, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen.<br/>
Nach Fristablauf werden die Daten gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.<br/>

Rechte der betroffenen Person<br/>
Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu:<br/>

Recht auf Auskunft<br/>
Recht auf Berichtigung<br/>
Recht auf Löschung<br/>
Recht auf Einschränkung der Verarbeitung<br/>
Recht auf Datenübertragbarkeit<br/>
Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.<br/>

Beschwerderecht bei der Aufsichtsbehörde<br/>
Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.<br/>

Widerspruchsrecht<br/>
Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu widersprechen.<br/>

Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.<br/>

Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, können Sie dieser Verarbeitung jederzeit durch Mitteilung an uns widersprechen.<br/>
Nach erfolgtem Widerspruch beenden wir die Verarbeitung der betroffenen Daten zum Zwecke der Direktwerbung.<br/>
          </p>
          {/* Rest of the content follows the same structure */}
        </div>
      </div>
    </div>
  );
}