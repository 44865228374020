import { Facebook, Twitter, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto py-12">
        <div className="grid md:grid-cols-4 gap-8">
          <div className="col-span-2 md:col-span-1">
            <div className="mb-4">
              <Logo className="h-10" />
            </div>
            <p className="text-gray-400 text-sm">
              Ihr zuverlässiger IT-Partner im Großraum Nürnberg seit über 12 Jahren.
            </p>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Kontakt</h3>
            <ul className="space-y-2 text-gray-400">
              <li>Weiltinger Straße 44</li>
              <li>D-90449 Nürnberg</li>
              <li>Tel: 0911-14886944</li>
              <li>anfragen@stumptner.it</li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-gray-400 hover:text-white transition">Startseite</Link></li>
              <li><a href="#services" className="text-gray-400 hover:text-white transition">Leistungen</a></li>
              <li><a href="#products" className="text-gray-400 hover:text-white transition">Produkte</a></li>
              <li><a href="#about" className="text-gray-400 hover:text-white transition">Über Uns</a></li>
              <li><Link to="/kontakt" className="text-gray-400 hover:text-white transition">Kontakt</Link></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-4">Rechtliches</h3>
            <ul className="space-y-2">
              <li><Link to="/impressum" className="text-gray-400 hover:text-white transition">Impressum</Link></li>
              <li><Link to="/datenschutz" className="text-gray-400 hover:text-white transition">Datenschutz</Link></li>
              <li><Link to="/agb" className="text-gray-400 hover:text-white transition">AGB</Link></li>
            </ul>
            <div className="mt-6">
              <h4 className="text-sm font-semibold mb-3">Folgen Sie uns</h4>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/stumptnerit/" className="text-gray-400 hover:text-white transition">
                  <Facebook className="h-6 w-6" />
                </a>
                <a href="https://x.com/stumptneri?s=21&t=EI1tnUwmc73NyudwcEIW6w" className="text-gray-400 hover:text-white transition">
                  <Twitter className="h-6 w-6" />
                </a>
                <a href="https://www.instagram.com/stumptnerit?igsh=eDFqNDI3NXEzNHhu" className="text-gray-400 hover:text-white transition">
                  <Instagram className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="border-t border-gray-800 mt-12 pt-8">
          <p className="text-center text-gray-400 text-sm">
            © {new Date().getFullYear()} Stumptner IT. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </footer>
  );
}