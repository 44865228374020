import React from 'react';

export default function ImpressumPage() {
  return (
    <div className="pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="mb-8">
          <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
            <a href="/" className="hover:text-red-500">Home</a>
            <span>•</span>
            <span className="text-red-500">Impressum</span>
          </div>
          <h1 className="text-3xl font-bold text-gray-900">Impressum</h1>
        </div>

        <div className="prose max-w-none">
          <h2>Gesetzliche Anbieterkennung:</h2>
          <p>
            Chris Stumptner<br />
            Weiltinger Str 44<br />
            90449 Nürnberg<br />
            Deutschland
          </p> <br />
          <p> <br /> 
            Telefon: 0911-14886944<br />
            FAX: 0911-93114949<br />
            E-Mail: anfragen@stumptner.it<br />
            USt-IdNr.: DE312152756 <br />
          </p>
          <p>
            Aufgrund des Kleinunternehmerstatus wird gemäß § 19 UStG die MwSt. in der Rechnung nicht ausgewiesen. <br />
          </p> <br /> <br />
          <p>
            Impressum für dieses und folgende Nebenprojekte gültig:<br />
            <a href="https://stumptner.it" className="text-red-500 hover:text-red-600">https://stumptner.it</a>
          </p> <br /> <br />

          <h2>Zuständige Aufsichtsbehörde:</h2> <br /> 
          <p>
            Bundesnetzagentur für Elektrizität, Gas, Telekommunikation, Post und Eisenbahnen<br />
            Tulpenfeld 4<br />
            53113 Bonn<br />
            Internet: www.bnetza.de<br />
            E-Mail: info@bnetza.de <br /> <br /> 
          </p>

          <h2>Alternative Streitbeilegung:</h2> <br />
          <p>
            Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform)
            bereit, aufrufbar unter <a href="https://ec.europa.eu/odr" className="text-red-500 hover:text-red-600">https://ec.europa.eu/odr</a>.
          </p> <br /> 
          <p>
            Wir sind seit 18.05.2018 Mitglied der Initiative "FairCommerce".<br />
            Nähere Informationen hierzu finden Sie unter <a href="http://www.fair-commerce.de" className="text-red-500 hover:text-red-600">www.fair-commerce.de</a>. <br />
          </p>

          <h2>Mitglied im Händlerbund</h2>
          <h3>Haftungsausschluss</h3>
          <h4>1. Inhalt Des Onlineangebotes</h4>
          <p>
            Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen...
          </p>
          {/* Rest of the content follows the same structure */}
        </div>
      </div>
    </div>
  );
}